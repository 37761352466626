#root {
  height: 100%;
}

.App{
  height: 100%;
}

.App-center {
  height: 100%;
}

.App-logo {
  max-width: 100%;
  width: 100vw;
}

.App-grid {
  height: 100%;
}

html, body {
  background-color: #282c34;
  height: 100%;
  margin: 0;
  padding: 0;
}
